import { useEffect } from "react";
import { Crisp } from "crisp-sdk-web";

const CrispChat = () => {
  useEffect(() => {
    Crisp.configure("a1059669-ef91-4920-90fd-bbdd1a636192");
    // Crisp.chat.hide();

    let isLinkInDocumentFlow = window.location.href
      .split("/")
      .includes("document");
    let isLinkInDraftFlow = window.location.href.split("/").includes("draft");
    if (isLinkInDraftFlow) {
      Crisp.setPosition("right");
    } else if (isLinkInDocumentFlow) {
      Crisp.setPosition("left");
    } else {
      Crisp.setPosition("right");
    }
  });

  Crisp.message.onMessageReceived(() => {
    Crisp.chat.show();
    Crisp.chat.open();
  });

  Crisp.chat.onChatClosed(() => {
    // Crisp.chat.hide();
  });

  return null;
};

export default CrispChat;
