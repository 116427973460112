import { animated } from "@react-spring/web";
import { useEffect, useState } from "react";
import {
  BsFillCheckCircleFill,
  BsPlayCircleFill,
  BsQuestionCircleFill,
  BsStars,
} from "react-icons/bs";
import { IoAdd, IoArrowBack } from "react-icons/io5";
import { SiCodereview } from "react-icons/si";
import { RiSendPlaneFill } from "react-icons/ri";
import { documentChatAPI, getUserAPI } from "../../api/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { convertStringToHTML, convertToCamelCase } from "../../functions";

import { v4 } from "uuid";
import { useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";
import { useDocumentElementsContext } from "./FinalDocumentContext";
import { updateDocument as updateDocumentReducer } from "../../redux/actions/documentActions";
import { paymentPlans } from "../../data";
import { BiLock, BiPlayCircle } from "react-icons/bi";
import { updateUser } from "../../redux/actions/userAction";
import { Oval } from "react-loader-spinner";
import { MdEditDocument, MdOutlineAdd } from "react-icons/md";
import { FaHandSparkles } from "react-icons/fa6";

let chatExamples = [
  {
    title: "Make Accurate Changes to the document",
    examples: [
      "Updating the doc: We have updated our prices of services to $40",
      "Adding new service: We now provide new services in our product",
      "We can now be liable for charges upto $100 from our service",
    ],
  },
  {
    title: "Clarify your questions",
    examples: [
      "What are the key points mentioned in this document?",
      "What should be improved to make this document more accurate?",
      "Give me a short brief on this document.",
    ],
  },
];

export const FinalDocumentChat = ({
  chatSectionProps,
  toggleChat,
  chatInfo,
  updatingElementId,
  updatingTextStream,
  setUpdatingElementId,
  setUpdatingTextStream,
  updatingElementData,
}) => {
  const icons = {
    question: () => (
      <BsQuestionCircleFill className="finalDocumentChatsHeaderIcon" />
    ),
    overview: () => <SiCodereview className="finalDocumentChatsHeaderIcon" />,
  };

  const { id, versionId } = useParams();
  const dispatch = useDispatch();

  const { documentElementsArray, updateArray: setDocumentElementsArray } =
    useDocumentElementsContext();
  const { documentInfo } = useSelector((state) => state.document);
  const { token, name } = useSelector((state) => state.user);

  const [updatingElementInfo, setUpdatingElementInfo] = updatingElementData;

  const [chatInput, setChatInput] = useState("");
  const [messages, setMessages] = useState([]);

  const [chats, setChats] = useState([
    // {
    //   role: "assistant",
    //   content: "How can I help you?",
    // },
  ]);

  const [streamMessage, setStreamMessage] = useState("");
  const [streamStatus, setStreamStatus] = useState("");

  const scrollChat = () => {
    if (document) {
      const element = document.getElementsByClassName(
        "finalDocumentPopupChats"
      );
      element[0].scrollTo(0, element[0].scrollHeight);
    }
  };

  function extractObject(inputString) {
    const idRegex = /id: (.*?)\s+/;
    const contentRegex = /content: (.*?)\s+summary:/s;
    const summaryRegex = /summary:\s*\n(.*?)\s*<>/s;

    const idMatch = inputString.match(idRegex);
    const contentMatch = inputString.match(contentRegex);
    const summaryMatch = inputString.match(summaryRegex);

    if (!idMatch || !contentMatch || !summaryMatch) {
      throw new Error("Invalid input string format");
    }

    const id = idMatch[1].trim();
    const content = contentMatch[1].trim();
    let summary = summaryMatch[1]
      .trim()
      .split("\n")
      .map((line) => line.trim());

    // Remove the "-" character from each item in the summary array
    summary = summary.map((item) => item.replace(/^- /, ""));

    // Remove the "*" character from the last item in the summary array
    if (summary.length > 0) {
      const lastItem = summary[summary.length - 1];
      summary[summary.length - 1] = lastItem.replace("*", "");
    }

    return {
      id,
      content,
      summary,
    };
  }

  let dataObject = null;

  // Make sure the updateDocument function is working correctly
  const updateDocument = (targetId, newParagraph, action = "ADD") => {
    const updatedArray = [];
    let foundTarget = false;

    //

    for (const item of documentElementsArray) {
      if (action === "UPDATE_CLAUSE" && item.id === targetId) {
        // For UPDATE_CLAUSE, directly update the matching paragraph
        updatedArray.push({
          ...item,
          text: newParagraph.text,
        });
        foundTarget = true;
      } else if (item.type === "SUBTITLE" && item.id === targetId) {
        // For ADD after subtitle
        foundTarget = true;
        updatedArray.push(item);
        updatedArray.push(newParagraph);
      } else if (foundTarget && item.type === "SUBTITLE") {
        foundTarget = false;
        updatedArray.push(item);
      } else {
        updatedArray.push(item);
      }
    }

    // If target was the last item and we're adding, ensure we still add the paragraph
    if (
      foundTarget &&
      action === "ADD" &&
      !updatedArray.includes(newParagraph)
    ) {
      updatedArray.push(newParagraph);
    }

    return updatedArray;
  };

  useEffect(() => {
    // //updatedDocumentArray);
    // const updatedDocumentArray = updateDocument("_7dVw5Bj_y", {
    //   type: "paragraph",
    //   id: uuidv4(),
    //   data: {
    //     text: "hello",
    //   },
    // });
    // //"updated 👋", updatedDocumentArray);
  }, []);

  const updateFinalChatObject = (object) => {
    const updatedDocumentArray = updateDocument(object.id, {
      type: "paragraph",
      id: uuidv4(),
      text: object.content,
    });

    setDocumentElementsArray(updatedDocumentArray);
    setUpdatingElementId("");

    dispatch(
      updateDocumentReducer({
        unsavedChanges: true,
      })
    );

    setTimeout(() => {
      let newChats = [...chats];
      newChats.push({
        role: "assistant",
        content: `${object.summary.map((eachSummary) => `${eachSummary}\n\n`)}`,
      });
      setChats(newChats);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }, 5000);
  };

  const [finalDocumentObject, setFinalDocumentObject] = useState(null);

  let newElement = null;

  let localUpdatingObject = {};

  async function readStream(stream) {
    const reader = stream.getReader();
    let specialFormatDetected = false;
    let specialFormatMessage = "";
    let buffer = "";

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        setStreamStatus("COMPLETED");
        focusSearchInput();

        if (specialFormatDetected && specialFormatMessage) {
          const updateObject = parseSpecialFormat(specialFormatMessage);
          if (updateObject) {
            handleDocumentUpdate(updateObject);
          }
        }
        return;
      }

      const textDecoder = new TextDecoder();
      const chunk = textDecoder.decode(value);

      // Split the chunk into individual JSON objects
      const jsonStrings = chunk.match(/\{[^}]+\}/g) || [];

      for (const jsonString of jsonStrings) {
        try {
          const parsed = JSON.parse(jsonString);
          if (parsed.type === "stream") {
            if (parsed.data.includes("*") || parsed.data.includes("- ID:")) {
              specialFormatDetected = true;
              specialFormatMessage += parsed.data;
            } else if (specialFormatDetected) {
              specialFormatMessage += parsed.data;
            } else {
              setStreamMessage((prev) => prev + parsed.data);
              scrollChat();
            }
          }
        } catch (err) {
          console.log("Error processing JSON:", err);
          continue;
        }
      }
    }
  }

  function parseSpecialFormat(message) {
    // Extract the content between the asterisks
    const match = message.match(/\*([\s\S]*?)\*/);
    if (!match) return null;

    const content = match[1];
    const lines = content.split("\n").map((line) => line.trim());
    const updateObject = {};

    let currentKey = "";
    let currentValue = "";

    lines.forEach((line) => {
      if (line.startsWith("- ")) {
        // If we have a previous key-value pair, save it
        if (currentKey && currentValue) {
          updateObject[currentKey] = currentValue.trim();
        }

        const [key, ...valueParts] = line.substring(2).split(":");
        currentKey = key.trim();
        currentValue = valueParts.join(":").trim().replace(/"/g, "");
      } else if (currentKey) {
        // Append to current value if we're in the middle of a value
        currentValue += (currentValue ? "\n" : "") + line;
      }
    });

    // Save the last key-value pair
    if (currentKey && currentValue) {
      updateObject[currentKey] = currentValue.trim();
    }

    return Object.keys(updateObject).length > 0 ? updateObject : null;
  }

  function handleDocumentUpdate(updateObject) {
    const { ID, Data, Action, Reason } = updateObject;

    console.log("ID:", ID, "Action:", Action, "Data:", Data, "Reason:", Reason);

    if (ID && Data) {
      // Create the new paragraph element with the complete Data
      const newParagraph = {
        type: "paragraph",
        id: Action === "UPDATE_CLAUSE" ? ID : uuidv4(), // Use existing ID for updates
        text: Data,
      };

      // Update the document array
      const updatedDocumentArray = updateDocument(ID, newParagraph, Action);

      // Update the document state
      setDocumentElementsArray(updatedDocumentArray);
      setUpdatingElementId("");

      console.log("Updated document array:", updatedDocumentArray);

      dispatch(
        updateDocumentReducer({
          unsavedChanges: true,
        })
      );

      // Add completion message to chat
      setTimeout(() => {
        setChats((prev) => [
          ...prev,
          {
            role: "assistant",
            content: `*\n- ID: "${ID}"\n- Action: "${Action}"\n- Data: "${Data}"\n- Reason: "${Reason}"\n*`,
          },
        ]);
        setStreamMessage("");
        setStreamStatus("NOT_STARTED");
      }, 1000);

      // Alert the user about the update
      alert(`Document updated successfully!\nReason: ${Reason}`);
    }
  }
  const focusSearchInput = () => {
    if (document) {
      const input = document.getElementById("chatInput");
      setTimeout(function () {
        input?.focus();
      }, 50);
    }
  };

  const getDocumentTexts = () => {
    // const htmlBody = atob(decodeURIComponent(documentInfo?.document_drafts[0]));
    // const textContent = htmlBody;
    // return convertStringToHTML(textContent).innerText;
  };

  const addToChat = async (loadedChats) => {
    setStreamStatus("PENDING");

    setTimeout(() => {
      const element = document.querySelector(".loadingChat");
      if (element) element.scrollIntoView();
    }, 200);

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/chatbot/question-document`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: token,
        },
        body: JSON.stringify({
          draftId: id,
          messages: loadedChats,
          version: versionId
            ? parseInt(versionId) - 1
            : documentInfo?.document_drafts.length - 1,
        }),
      }
    );

    if (response.ok) {
      await readStream(response.body);
    } else {
      setStreamStatus("NOT_STARTED");
    }
  };

  const [userInfo, setUserInfo] = useState(null);

  const getUserInfo = async () => {
    const response = await getUserAPI();
    if (response.data) {
      setUserInfo(response.data);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  const chatWithDocument = async (e, needToAddMessage = true) => {
    if (e) e.preventDefault();

    // if (
    //   userInfo &&
    //   (userInfo.plan === paymentPlans.PRO_PLAN ||
    //     documentInfo?.status === "ACCESS_GRANTED_FOR_DOCUMENT")
    // ) {
    if (chatInfo.length !== 0 && streamStatus !== "PENDING") {
      let loadedChats = [];
      await setChats((prev) => {
        loadedChats = [...prev, { role: "user", content: chatInput }];
        return [...prev, { role: "user", content: chatInput }];
      });
      setChatInput("");
      scrollChat();

      addToChat(loadedChats);
    }
    // }
  };

  useEffect(() => {
    getDocumentTexts();

    // // //"chat info: ", chatInfo);

    if (chatInfo) {
      if (chatInfo.data && chatInfo.data.autoSend) {
        let loadedChats = [];
        setChats((prev) => {
          loadedChats = [
            ...prev,
            { role: "user", content: chatInfo.data.question },
          ];
          return [...prev, { role: "user", content: chatInfo.data.question }];
        });
      }

      addToMessages("user", chatInfo.title); // chatWithDocument(null, false);
    }
  }, [chatInfo]);

  const addToMessages = (type, text) => {
    setMessages([
      ...messages,
      {
        id: v4(),
        role: type,
        content: text,
      },
    ]);
  };

  const handleSubmit = async (e) => {
    chatWithDocument(e);
  };

  useEffect(() => {
    if (
      streamMessage.length > 0 &&
      streamStatus === "COMPLETED" &&
      (!updatingElementId || updatingElementId?.length === 0)
    ) {
      setChats((prev) => [
        ...prev,
        {
          role: "assistant",
          content: streamMessage,
        },
      ]);
      setStreamMessage("");
      setStreamStatus("NOT_STARTED");
    }
  }, [streamStatus]);

  const [showFullChats, setShowFullChats] = useState(false);

  let timer;
  var scrolling = false;

  function makeTransparent() {
    let overlay = document.getElementById("overlay");

    overlay.style.backgroundColor = "transparent"; // Adjust the rgba values to set the desired transparent color
  }

  function resetTimer() {
    let overlay = document.getElementById("overlay");

    makeTransparent(); // Call makeTransparent to set the overlay to transparent
    clearTimeout(timer);
    timer = setTimeout(function () {
      if (!scrolling) {
        overlay.style.backgroundColor = "#191f2fcf"; // Reset to default color after 3 seconds of inactivity
      }
    }, 1000);
  }

  function startTimer() {
    let overlay = document.getElementById("overlay");

    clearTimeout(timer);
    timer = setTimeout(function () {
      if (!scrolling) {
        overlay.style.backgroundColor = "#191f2fcf"; // Reset to default color after 3 seconds of inactivity
      }
    }, 1000);
  }

  window.addEventListener("scroll", function () {
    let overlay = document.getElementById("overlay");

    if (overlay) {
      scrolling = true;
      clearTimeout(timer);
      makeTransparent(); // Set the overlay to transparent when scrolling
      timer = setTimeout(function () {
        scrolling = false;
        overlay.style.backgroundColor = "#191f2fcf"; // Reset to default color after 3 seconds of inactivity and no scrolling
      }, 1000);
    }
  });

  const convertChatMessageToObject = (text) => {
    if (/^\*[\s\S]*\*$/.test(text.trim())) {
      return parseSpecialFormat(text);
    }
    return null;
  };

  if (userInfo) {
    return (
      <>
        <animated.div
          style={chatSectionProps}
          className={`finalDocumentRightSectionPopup finalDocumentRightSectionPopupWithChats
          `}
        >
          {/* {!showFullChats && (
            <p className="finalDocumentRightSectionInfoLink">
              <p className="finalDocumentRightSectionInfoLinkText">
                What can you do with Pilot? Here's a quick demo:{" "}
              </p>
              <BsPlayCircleFill className="finalDocumentRightSectionInfoLinkIcon" />
            </p>
          )} */}
          <div className="finalDocumentPopupChatSection">
            {/* <div className="finalDocumentChatsHeader">
      <div className="finalDocumentChatHeaderRight">
        <p className="finalDocumentChatsHeaderText">Chat with Pilot</p>
        <p className="finalDocumentChatsHeaderDescription">
          Make Changes to the document and ask questions.
        </p>
      </div>
    </div> */}

            {chats.length === 0 && showFullChats && (
              <div className="getStartedFinalDocumentChat">
                <div className="getStartedFinalDocumentChatContainer">
                  <div className="getStartedFinalDocumentChatHeader">
                    <FaHandSparkles className="getStartedFinalDocumentChatHeaderIcon" />
                    <p className="getStartedFinalDocumentChatHeaderText">
                      Hey, it's Pilot!
                    </p>
                    <p className="getStartedFinalDocumentChatDescription">
                      Here's what I can help you with:
                    </p>
                  </div>

                  {chatExamples.map((eachChatExample) => (
                    <div className="getStartedFinalDocumentSection">
                      <p className="getStartedFinalDocumentChatSectionText">
                        {eachChatExample.title}
                      </p>
                      {eachChatExample.examples.map((eachExample) => (
                        <p
                          className="getStartedFinalDocumentChatSectionText"
                          onClick={() => setChatInput(eachExample)}
                        >
                          <MdOutlineAdd className="getStartedFinalDocumentChatSectionTextIcon" />{" "}
                          <p className="getStartedFinalDocumentChatSectionTextSection">
                            "{eachExample}"
                          </p>
                        </p>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* {JSON.stringify(updatingElementInfo)} */}

            {chats.length !== 0 && showFullChats && (
              <div className="finalDocumentPopupChats">
                {/* {chats.length === 1 && (
        <div className="chatTutorial">
          <p className="chatTutorialHeader">
            You can Say "I also collect data from Forms" for Pilot to
            automatically change parts of the document for your needs
          </p>
        </div>
      )} */}

                {chats.map((eachMessage) => {
                  if (eachMessage.role === "user") {
                    return (
                      <div className="eachChat rightChat">
                        <p className="userChatName">YOU</p>
                        <p className="eachChatText">{eachMessage.content}</p>
                      </div>
                    );
                  } else {
                    return (
                      <>
                        <div className="eachChat leftChat">
                          <p className="userChatName">PILOT</p>

                          {/* {eachMessage.content} */}

                          {/^(\*[\s\S]*- ID: [\s\S]*)$/.test(
                            eachMessage.content
                          ) ? (
                            <>
                              {convertChatMessageToObject(
                                eachMessage.content
                              ) &&
                                convertChatMessageToObject(eachMessage.content)
                                  .Reason && (
                                  <p
                                    className="eachChatText"
                                    style={{
                                      marginBottom: 10,
                                      fontStyle: "italic",
                                      marginTop: 4,
                                    }}
                                  >
                                    {
                                      convertChatMessageToObject(
                                        eachMessage.content
                                      ).Reason
                                    }
                                  </p>
                                )}
                              <div className="updatingDocumentLoader">
                                <MdEditDocument className="updatingDocumentLoaderLeftIcon" />

                                <div className="updatingDocumentLoaderContent">
                                  <p className="updatingDocumentLoaderHeader">
                                    Document was updated
                                  </p>
                                  <p className="updatingDocumentLoaderText">
                                    Document was updated to match your new
                                    requirements and it is also saved as a new
                                    version of the document.
                                  </p>
                                </div>

                                <BsFillCheckCircleFill
                                  className="updatingDocumentLoaderIcon"
                                  fontSize={24}
                                  color="green"
                                />
                                {/* 
                              <Oval
                                height={20}
                                width={20}
                                color="#1252f3"
                                wrapperStyle={{ marginLeft: 5 }}
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#1252f330"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                              /> */}
                              </div>
                            </>
                          ) : (
                            <p className="eachChatText">
                              {eachMessage.content}
                            </p>
                          )}
                        </div>
                      </>
                    );
                  }
                })}
                {streamStatus === "PENDING" && (
                  <div className="eachChat leftChat loadingChat">
                    <p className="userChatName">PILOT</p>

                    {/^(\*[\s\S]*- ID: [\s\S]*)$/.test(streamMessage) ? (
                      <div className="updatingDocumentLoader">
                        <MdEditDocument className="updatingDocumentLoaderLeftIcon" />

                        <div className="updatingDocumentLoaderContent">
                          <p className="updatingDocumentLoaderHeader">
                            Updating the document
                          </p>
                          <p className="updatingDocumentLoaderText">
                            The document is being changed to match your new
                            requirments
                          </p>
                        </div>

                        <Oval
                          height={20}
                          width={20}
                          color="#1252f3"
                          wrapperStyle={{ marginLeft: 5 }}
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#1252f330"
                          wrapperClass="updatingDocumentLoaderIcon"
                          strokeWidth={5}
                          strokeWidthSecondary={5}
                        />
                      </div>
                    ) : (
                      <>
                        <p className="eachChatText">
                          {streamMessage.length === 0
                            ? "Thinking..."
                            : streamMessage}
                        </p>
                        <div className="loadingCircle"></div>
                      </>
                    )}
                  </div>
                )}
                {/* <div className="eachChat rightChat">
    <p className="eachChatText">
      What are the confidential things that the partners can't share
      with others?
    </p>
  </div>

  <div className="eachChat leftChat">
    <p className="eachChatText">
      In a partnership agreement, there are typically certain
      confidential matters that partners are prohibited from sharing
      with others. These may include sensitive financial information,
      trade secrets, proprietary business strategies, customer lists,
      marketing plans, and any other proprietary or confidential
      information specific to the partnership. The intention behind
      these restrictions is to protect the interests and competitive
      advantage of the partnership. It's important for partners to abide
      by these confidentiality provisions to maintain trust, preserve
      business confidentiality, and safeguard the partnership's valuable
      assets.
    </p>
  </div>

  <div className="eachChat rightChat">
    <p className="eachChatText">
      What are the confidential things that the partners can't share
      with others?
    </p>
  </div>

  <div className="eachChat leftChat">
    <p className="eachChatText">
      In a partnership agreement, there are typically certain
      confidential matters that partners are prohibited from sharing
      with others. These may include sensitive financial information,
      trade secrets, proprietary business strategies, customer lists,
      marketing plans, and any other proprietary or confidential
      information specific to the partnership. The intention behind
      these restrictions is to protect the interests and competitive
      advantage of the partnership. It's important for partners to abide
      by these confidentiality provisions to maintain trust, preserve
      business confidentiality, and safeguard the partnership's valuable
      assets.
    </p>
  </div>

  <div className="eachChat rightChat">
    <p className="eachChatText">
      What are the confidential things that the partners can't share
      with others?
    </p>
  </div>

  <div className="eachChat leftChat">
    <p className="eachChatText">
      In a partnership agreement, there are typically certain
      confidential matters that partners are prohibited from sharing
      with others. These may include sensitive financial information,
      trade secrets, proprietary business strategies, customer lists,
      marketing plans, and any other proprietary or confidential
      information specific to the partnership. The intention behind
      these restrictions is to protect the interests and competitive
      advantage of the partnership. It's important for partners to abide
      by these confidentiality provisions to maintain trust, preserve
      business confidentiality, and safeguard the partnership's valuable
      assets.
    </p>
  </div> */}
              </div>
            )}

            <div className="finalDocumentChatInputContainer">
              {/*  */}

              {/* {userInfo.plan === paymentPlans.FREE_PLAN ? (
        <div
          className="finalDocumentChatUpgrade"
          onClick={() => {
            dispatch(
              updateUser({
                showUpgradeModal: true,
              })
            );
          }}
        >
          <BiLock className="finalDocumentChatUpgradeIcon" />
          <p className="finalDocumentChatUpgradeText">
            <span className="finalDocumentUpgradeLink">Upgrade</span> to
            make unlimited changes using plain english
          </p>
        </div>
      ) : ( */}
              <form
                onSubmit={(e) => chatWithDocument(e)}
                className="finalDocumentChatInputContent"
              >
                <BsStars className="finalDocumentChatInputIcon" />

                {/* <div className="finalDocumentChatActiveIcon"></div> */}

                <input
                  type="text"
                  className="finalDocumentChatInput"
                  placeholder={
                    showFullChats
                      ? "Try 'Give me the key points of this document'"
                      : "Hi, I am Pilot, I'll help you with this document"
                  }
                  value={chatInput}
                  onChange={(e) => setChatInput(e.target.value)}
                  onFocus={() => setShowFullChats(true)}
                  // onBlur={() => {
                  //   if (streamStatus !== "PENDING") {
                  //     setShowFullChats(false);
                  //   }
                  // }}
                />

                {/* {chatInput.length !== 0 && (
          <div className="finalDocumentChatSendButton">
            <RiSendPlaneFill />
          </div>
        )} */}
              </form>
              {/* )} */}
            </div>
          </div>
        </animated.div>

        {showFullChats && (
          <div
            id="overlay"
            className="finalDocumentRightSectionOverlay"
            onMouseMove={() => resetTimer()}
            onMouseOut={() => startTimer()}
            onClick={() => {
              if (streamStatus !== "PENDING") {
                setShowFullChats(false);
              }
            }}
          ></div>
        )}
      </>
    );
  }
};
