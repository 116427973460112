import React from "react";
// import * as Select from "@radix-ui/react-select";
// import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import "../../select.css";

const SelectDropdown = ({
  values,
  placeholder,
  defaultValue,
  value,
  onValueChange,
  valueLabel,
  textLabel,
  style,
}) => (
  <>
    <select
      placeholder={placeholder}
      defaultValue={defaultValue}
      value={value}
      onChange={(e) => onValueChange(e.target.value)}
      className="onboardingSelect"
      style={style}
    >
      {values.map((eachValue) => (
        <option value={eachValue[valueLabel]}>{eachValue[textLabel]}</option>
      ))}
    </select>
  </>
  // <Select.Root
  //   defaultValue={defaultValue}
  //   value={value}
  //   onValueChange={onValueChange}
  // >
  //   <Select.Trigger className="SelectTrigger" aria-label="Food">
  //     <Select.Value placeholder={placeholder} />
  //     <Select.Icon className="SelectIcon">
  //       <ChevronDownIcon />
  //     </Select.Icon>
  //   </Select.Trigger>
  //   <Select.Portal>
  //     <Select.Content className="SelectContent">
  //       <Select.ScrollUpButton className="SelectScrollButton">
  //         <ChevronUpIcon />
  //       </Select.ScrollUpButton>
  //       <Select.Viewport className="SelectViewport">
  //         <Select.Group>
  //           {values.map((eachValue) => (
  //             <SelectItem value={eachValue[valueLabel]}>
  //               {eachValue[textLabel]}
  //             </SelectItem>
  //           ))}

  //         </Select.Group>

  //       </Select.Viewport>
  //       <Select.ScrollDownButton className="SelectScrollButton">
  //         <ChevronDownIcon />
  //       </Select.ScrollDownButton>
  //     </Select.Content>
  //   </Select.Portal>
  // </Select.Root>
);

// const SelectItem = React.forwardRef(
//   ({ children, className, ...props }, forwardedRef) => {
//     return (
//       <Select.Item
//         className={classnames("SelectItem", className)}
//         {...props}
//         ref={forwardedRef}
//       >
//         <Select.ItemText>{children}</Select.ItemText>
//         <Select.ItemIndicator className="SelectItemIndicator">
//           <CheckIcon />
//         </Select.ItemIndicator>
//       </Select.Item>
//     );
//   }
// );

export default SelectDropdown;
