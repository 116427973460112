import React, { useState } from "react";
import { BiHide, BiShow } from "react-icons/bi";
import { Oval } from "react-loader-spinner";
import { addUpdateAPIKeyAPI, getAPIKeyAPI } from "../../api/apiCalls";

export const AccountSettings = () => {
  const [apiKey, setApiKey] = useState("*****");
  const [showApiKey, setShowApiKey] = useState(false);
  const [isSavingApiKey, setIsSavingApiKey] = useState(false);
  const [isShowingApiKey, setIsShowingApiKey] = useState(false);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };

  const handleSaveApiKey = async () => {
    setIsSavingApiKey(true);
    try {
      const response = await addUpdateAPIKeyAPI({ apiKey });
      // Save the API key to the user's account
      // console.log("Saving API key:", apiKey);
      setShowUpdateMessage(true);
    } catch (error) {
      // console.error("Error saving API key:", error);
    } finally {
      setIsSavingApiKey(false);
      setTimeout(() => {
        setShowUpdateMessage(false);
      }, 3000);
    }
  };

  const toggleShowApiKey = async () => {
    setIsShowingApiKey(true);
    setShowApiKey(!showApiKey);
    if (showApiKey) {
      // Hide the API key
      setApiKey("*****");
      setIsShowingApiKey(false);
    } else {
      // Fetch the API key from the server
      try {
        const response = await getAPIKeyAPI();
        setApiKey(response.data.apiKey);
      } catch (error) {
        console.error("Error fetching API key:", error);
      } finally {
        setIsShowingApiKey(false);
      }
    }
  };

  const handleHideApiKey = () => {
    setApiKey("*****");
    setShowApiKey(false);
  };

  return (
    <div className="contextSetting">
      <div className="contextSettingUploadContainer">
        <p className="contextSettingUploadHeader">Add your own API key</p>

        <p className="contextSettingUploadDescription">
          You can add your own API key here to integrate with our platform. You
          can change the API Key once you click on the eye icon.{" "}
        </p>
        <a href="https://platform.openai.com/api-keys" target="_blank">
          How to get my API Key?
        </a>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ position: "relative" }}>
            <input
              type="text"
              placeholder="Enter your API key"
              value={showApiKey ? apiKey : "*****"}
              onChange={handleApiKeyChange}
              className="settingsInput"
              style={{ paddingRight: "40px" }}
              readOnly={!showApiKey}
            />
            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#1252f320",
                padding: "5px",
                width: "25px",
                height: "25px",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={toggleShowApiKey}
            >
              {isShowingApiKey ? (
                <Oval height={20} width={20} color="#1252f3" />
              ) : showApiKey ? (
                <BiHide size={20} onClick={handleHideApiKey} />
              ) : (
                <BiShow size={20} />
              )}
            </div>
          </div>
          <div
            className="ButtonMain"
            style={{ width: "125px", marginLeft: "20px" }}
            onClick={handleSaveApiKey}
            disabled={isSavingApiKey}
          >
            {isSavingApiKey ? (
              <Oval height={20} width={20} color="#fff" />
            ) : (
              "Save API Key"
            )}
          </div>
        </div>
        {showUpdateMessage && (
          <div
            style={{
              marginTop: "10px",
              padding: "10px",
              backgroundColor: "#1252f320",
              color: "#1252f3",
              borderRadius: "5px",
            }}
          >
            API key updated successfully!
          </div>
        )}
      </div>
    </div>
  );
};
