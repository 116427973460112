import React from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../redux/actions/userAction";
import { Crisp } from "crisp-sdk-web";

export const ErrorModal = () => {
  const dispatch = useDispatch();
  const { errorModal } = useSelector((state) => state.user);

  const exitModal = () => {
    dispatch(updateUser({ errorModal: false }));
  };

  return (
    <Dialog.Root
      open={errorModal}
      onOpenChange={(open) => !open && exitModal()}
    >
      <Dialog.Portal>
        <Dialog.Overlay
          style={{
            position: "fixed",
            inset: 0,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        />
        <Dialog.Content
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            borderRadius: "8px",
            padding: "24px",
            width: "500px",
            maxWidth: "90vw",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Dialog.Title
            style={{
              fontSize: "18px",
              fontWeight: "600",
              color: "#DC2626",
              marginBottom: "12px",
            }}
          >
            Credits Exhausted
          </Dialog.Title>
          <Dialog.Description
            style={{
              fontSize: "14px",
              color: "#4B5563",
              marginBottom: "16px",
            }}
          >
            You've used all your available OpenAI credits. To continue using our
            services:
          </Dialog.Description>
          <ul
            style={{
              fontSize: "14px",
              color: "#4B5563",
              marginBottom: "16px",
              paddingLeft: "20px",
              listStyleType: "none",
            }}
          >
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "8px", color: "#DC2626" }}>•</span>
              Go to{" "}
              <a
                href="https://openai.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#2563EB", textDecoration: "underline", margin: "0px 4px" }}
              >
                openai.com
              </a>{" "}
              and recharge your credits
            </li>
            <li
              style={{
                marginBottom: "8px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span style={{ marginRight: "8px", color: "#DC2626" }}>•</span>
              Contact us through the chatbox (in the bottom left corner) 
            </li>
          </ul>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Dialog.Close asChild>
              <button
                style={{
                  backgroundColor: "#DC2626",
                  color: "white",
                  fontSize: "14px",
                  padding: "8px 16px",
                  borderRadius: "4px",
                  border: "none",
                  cursor: "pointer",
                  marginTop: "20px",
                }}
                onClick={exitModal}
              >
                Close
              </button>
            </Dialog.Close>
          </div>
          <Dialog.Close asChild>
            <button
              style={{
                position: "absolute",
                top: "8px",
                right: "8px",
                color: "#9CA3AF",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              aria-label="Close"
              onClick={exitModal}
            >
              <Cross2Icon />
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
