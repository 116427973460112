import React from "react";
import { InfinitySpin, Oval } from "react-loader-spinner";

export const DocumentViewerLoader = ({
  text = "Loading PDF",
  size = "small",
}) => {
  return (
    <div className="documentViewerLoader">
      <InfinitySpin
        width={150}
        color="#1252f3"
        wrapperStyle={{}}
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor="#1252f320"
        strokeWidth={5}
        strokeWidthSecondary={5}
      />

      <p
        className="documentViewerLoaderText"
        style={{ fontSize: size === "large" ? "16px" : "14px" }}
      >
        {text}
      </p>
    </div>
  );
};
